@font-face {
  font-style: normal;
  font-weight: 400;
  font-family: "Circe";
  font-display: swap;
  src: url("../fonts/Circe-Regular.woff2") format("woff2"), url("../fonts/Circe-Regular.woff") format("woff");
}

@font-face {
  font-style: normal;
  font-weight: 800;
  font-family: "Circe";
  font-display: swap;
  src: url("../fonts/Circe-ExtraBold.woff2") format("woff2"), url("../fonts/Circe-ExtraBold.woff") format("woff");
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-family: "CirceRounded";
  font-display: swap;
  src: url("../fonts/CirceRounded-Regular3.woff2") format("woff2"), url("../fonts/CirceRounded-Regular3.woff") format("woff");
}

html {
  font-family: "Circe", sans-serif;
}

body {
  font-family: "Circe";
}

body .content-wrapper {
  background-color: #eff0f0;
}

body .table {
  font-size: 0.9rem;
}

body .table th,
body .table td {
  padding: 0.3rem;
}

body a {
  color: #0b597a;
}

.content .bg-amigo {
  background-color: #a6c8ee;
  border-color: #092125;
  color: #0c3b6c;
}

.content .bg-amigo:hover {
  background-color: #0c3b6c;
  border-color: #092125;
  color: #a6c8ee;
}

.content .bg-amigo.active-btn {
  background-color: #0c3b6c;
  border-color: #092125;
  color: #a6c8ee;
}

.content .bg-amigo .btn {
  color: white;
}
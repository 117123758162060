@import "config";
@import "font";

html {
  font-family: "Circe", sans-serif;
}

body{
  font-family: "Circe";
  .content-wrapper{
      background-color: #eff0f0;
  }
  .table{
    font-size: 0.9rem;
    th, td{
      padding: 0.3rem;
    }
  }

  a{
    color: #0b597a;
  }
}

.content .bg-amigo{
  background-color: #a6c8ee;
  border-color: #092125;
  color: #0c3b6c;
  &:hover{
    background-color: #0c3b6c;
    border-color: #092125;
    color: #a6c8ee;
  }
  &.active-btn {
    background-color: #0c3b6c;
    border-color: #092125;
    color: #a6c8ee;
  }
}
.content .bg-amigo .btn{
  color: white;
}


